import { useState, useEffect, memo, useRef } from "react";
import "./Banner.scss";
import { isEqual } from "lodash";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import Typed from "typed.js";
import { useCustomTranslation } from "../../../../hooks/useCustomTranslation";
import Button from "../../../../components/Button/Button";
import { useClick } from "../../../../hooks/useClick";
const BannerTitle = {
  Language: [
    "",
    "TRUSTLESS  AGENTS",
    "",
    "ON-CHAIN INTELLIGENCE",
    "",
    "AUTONOMOUS WORLDS",
  ],
  語言: ["", "自進化世界", "", "可組合的經驗", "", "鏈上秘密"],
  언어: ["", "자기 진화하는 세계", "", "조합 가능한 경험", "", "온체인 시크릿"],
};
const Banner = memo(() => {
  const isM = useIsMobile();
  const { t } = useCustomTranslation();
  const { handleWhite, handleEconomics } = useClick();
  return (
    <div className="home_banner animate">
      <TypeWidget />
      {isM ? <Title /> : null}
      <h2 className="element-from-bottom">ON ZYPHER NETWORK </h2>
      <p className="home_banner_text element-from-bottom">
        ZK Computing Layer for Verifiable AI Agents and Applications
      </p>
      {/* <ExploreGamesBtn /> */}
      <div className="home-banner-btn">
        <Button
          onClick={handleWhite}
          className="explore_games_btn element-from-bottom"
          label="White paper"
          classNameInner={"explore_games_btn_inner"}
        />
        <Button
          onClick={handleEconomics}
          className="explore_games_btn element-from-bottom"
          label="Econ paper"
          classNameInner={"explore_games_btn_inner"}
        />
      </div>
    </div>
  );
}, isEqual);
const Title = memo(() => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t } = useCustomTranslation();

  useEffect(() => {
    const _BannerTitle = BannerTitle[
      t("Language") as "Language" | "언어" | "語言"
    ] as unknown as string[];
    const text = _BannerTitle[currentIndex];
    let count = 0;

    const interval = setInterval(() => {
      setCurrentText((prevText) => {
        if (count >= text.length) {
          clearInterval(interval);
          setCurrentIndex((prevIndex) => (prevIndex + 1) % _BannerTitle.length);
          return prevText;
        }
        count++;
        return text.slice(0, count);
      });
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [currentIndex, t("Language")]);

  return (
    <div className="home_banner_title element-from-bottom">
      <h1>
        {currentText}
        <em>|</em>
      </h1>
    </div>
  );
}, isEqual);

const TypeWidget = memo(() => {
  const typed = useRef<Record<string, Typed>>({});
  const elUS = useRef<HTMLHeadingElement>(null);
  const elTW = useRef<HTMLHeadingElement>(null);
  const elKR = useRef<HTMLHeadingElement>(null);
  const isM = useIsMobile();
  const { t } = useCustomTranslation();

  useEffect(() => {
    // 创建Typed实例并存储到ref中
    const createTypedInstance = (
      el: React.RefObject<HTMLHeadingElement>,
      strings: string[]
    ) => {
      if (el.current) {
        typed.current[el.current.id] = new Typed(el.current, {
          strings,
          startDelay: 0,
          typeSpeed: 50,
          backDelay: 800,
          backSpeed: 25,
          loop: true,
          onStringTyped: (arrpos, self) => {
            if (arrpos % 2 === 1) {
              self.stop();
              setTimeout(() => {
                self.start();
              }, 1200);
            }
          },
        });
      }
    };

    // 销毁Typed实例
    const destroyTypedInstance = (el: React.RefObject<HTMLHeadingElement>) => {
      if (el.current && typed.current[el.current.id]) {
        typed.current[el.current.id].destroy();
        delete typed.current[el.current.id];
      }
    };

    // 初始化或更新Typed实例
    const initTyped = () => {
      destroyTypedInstance(elUS);
      destroyTypedInstance(elTW);
      destroyTypedInstance(elKR);

      createTypedInstance(elUS, BannerTitle["Language"]);
      createTypedInstance(elTW, BannerTitle["語言"]);
      createTypedInstance(elKR, BannerTitle["언어"]);
    };

    initTyped();

    // 在组件卸载时销毁所有Typed实例
    return () => {
      destroyTypedInstance(elUS);
      destroyTypedInstance(elTW);
      destroyTypedInstance(elKR);
    };
  }, [Boolean(BannerTitle)]);

  // 只有在非移动端才渲染元素
  if (isM) {
    return null;
  }
  return (
    <>
      <div
        className="home_banner_title element-from-bottom"
        style={{ display: t("Language") === "Language" ? "flex" : "none" }}
      >
        <h1 ref={elUS} id="elUS" />
      </div>
      <div
        className="home_banner_title element-from-bottom"
        style={{ display: t("Language") === "語言" ? "flex" : "none" }}
      >
        <h1 ref={elTW} id="elTW" />
      </div>
      <div
        className="home_banner_title element-from-bottom"
        style={{ display: t("Language") === "언어" ? "flex" : "none" }}
      >
        <h1 ref={elKR} id="elKR" />
      </div>
    </>
  );
});
export default Banner;
