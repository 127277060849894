import { isEqual } from "lodash";
import { memo } from "react";
import HomeSection from "../widget/HomeSection/HomeSection";
import Title from "../widget/Title/Title";
import { preStaticUrl } from "../../../../constants/env_config";
import "./News.scss";
import SvgComponent from "../../../../components/SvgComponent/SvgComponent";
import { useCustomTranslation } from "../../../../hooks/useCustomTranslation";
const _preStaticUrl = preStaticUrl + "/img/zypher/news/";

const newsList: INavItem[] = [
  {
    imgPath: _preStaticUrl + "news_nav4.webp",
    title:
      "Zypher 2025: Year of Escape Velocity (Autonomous Game Agents, Prover Market, Our Predictions)",
    timestamp: "Jan 02, 2025",
    link: "https://zyphergames.substack.com/p/zypher-2025-year-of-escape-velocity",
  },
  {
    imgPath: _preStaticUrl + "news_nav5.webp",
    title: "Nova: Reshaping the Future of ZK Games (Part 1)",
    timestamp: "Jan 02, 2025",
    link: "https://zyphergames.substack.com/p/nova-reshaping-the-future-of-zk-games",
  },
  {
    imgPath: _preStaticUrl + "news_nav6.webp",
    title:
      "Zypher Network: Server Abstraction Narrative, the Narrative Hotspot in GameFi?",
    timestamp: "Oct 31, 2024",
    link: "https://zyphergames.substack.com/p/zypher-network-server-abstraction",
  },
];
const News = memo(() => {
  const { t } = useCustomTranslation();
  return (
    <HomeSection classNames="news animate">
      <Title title={t("In the News")} />
      <div className="news_nav">
        <a
          href="https://zyphergames.substack.com"
          target="_blank"
          rel="noreferrer"
          className="all_media"
        >
          {t("All Media")}
          <SvgComponent src={preStaticUrl + "/img/icon/arrow-right.svg"} />
        </a>
        <ul>
          {newsList.map((v) => (
            <NavItem item={v} key={v.title} />
          ))}
        </ul>
      </div>
    </HomeSection>
  );
}, isEqual);

type INavItem = {
  imgPath: string;
  title: string;
  timestamp: string;
  link: string;
};
type INavItemProps = {
  item: INavItem;
};
const NavItem = memo(({ item }: INavItemProps) => {
  const { t } = useCustomTranslation();
  return (
    <li>
      <a href={item.link} target="_blank" rel="noreferrer">
        <img className="news_nav_img" src={item.imgPath} alt={item.title} />
        <p className="news_nav_title">{t(item.title)}</p>
        <p className="news_nav_time">{t(item.timestamp)}</p>
      </a>
    </li>
  );
}, isEqual);

export default News;
