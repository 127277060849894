import { isEqual } from "lodash";
import { memo } from "react";
import { preStaticUrl } from "../../../../constants/env_config";
import "./Partners.scss";
import HomeSection from "../widget/HomeSection/HomeSection";
import PartnerCarousel from "./PartnerCarousel";
import { useCustomTranslation } from "../../../../hooks/useCustomTranslation";

const partnerList01 = [
  "Group 1312319991",
  "Frame 3689",
  "Frame 3709",
  "Frame 3708",
  "Frame 3717",
  "Frame 3720",
  "Frame 3710",
  "Litentry",
  "Nexus",
  "Orochi Network",
  "Sonic",
  "UXUY",
  "ZeroBase",
  "Frame 3721",
  "Frame 3684",
  "Frame 3686",
  "Frame 3687",
  "Group 1312319992",
  "Group 1312319993",
  "Frame 3713",
  "Frame 3714",
  "Frame 3712",
];
const partnerList02 = [
  "Group 1312319994",
  "Group 1312319995",
  "Frame 3715",
  "Frame 3716",
  "Frame 3692",
  "Frame 3711",
  "Frame 3718",
  "B² Network",
  "B3",
  "BAS",
  "Cycle Network",
  "DoraHacks",
  "efrogs",
  "Frame 3688",
  "Frame 3683",
  "Frame 3685",
  "Frame 3694",
  "Frame 3695",
  "Frame 3696",
  "Frame 3697",
];
const partnerList03 = [
  "Group 1312319996",
  "Group 1312319997",
  "Frame 3693",
  "Frame 3698",
  "Frame 3699",
  "Frame 3700",
  "Frame 3701",
  "Frame 3702",
  "Cogitent Ventures",
  "Expander",
  "Fireverse",
  "Gravity",
  "KODO",
  "Linpuss",
  "Frame 3719",
  "Frame 3722",
  "Frame 3703",
  "Frame 3704",
  "Frame 3705",
  "Frame 3706",
  "Frame 3707",
];
const Partners = memo(() => {
  const { t } = useCustomTranslation();
  return (
    <HomeSection classNames="partners animate">
      <h2
        dangerouslySetInnerHTML={{
          __html:
            "LEAD THE <em>TRUSTLESS AI</em> Revolution <em>TOGETHER.</em>",
        }}
      />
      {[partnerList01, partnerList02, partnerList03].map((list, listIndex) => (
        <PartnerCarousel key={listIndex} partners={list} index={listIndex} />
      ))}
      <div className="partners_bg1" />
      <div className="partners_bg2" />
    </HomeSection>
  );
}, isEqual);
export default Partners;
