import { changeLanguage } from "i18next";
import { isEqual } from "lodash";
import { memo, useCallback } from "react";
import HomeSection from "../widget/HomeSection/HomeSection";
import { preStaticUrl } from "../../../../constants/env_config";
import storage from "../../../../utils/storage";
import CommunityLink from "../widget/CommunityLink/CommunityLink";

import "./Footer.scss";
import { SolutionsList } from "../Solutions/Solutions";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { useCustomTranslation } from "../../../../hooks/useCustomTranslation";
type ILanguageList = {
  label: string;
  Language: string;
  keyValue: string;
  img: string;
};
export const languageList: ILanguageList[] = [
  {
    label: "English",
    Language: "Language",
    keyValue: "en_US",
    img: preStaticUrl + "/img/layout/en_US.png",
  },
  {
    label: "한국어",
    Language: "언어",
    keyValue: "ko_KR",
    img: preStaticUrl + "/img/layout/ko_KR.png",
  },
  {
    label: "中文",
    Language: "語言",
    keyValue: "zh_TW",
    img: preStaticUrl + "/img/layout/zh_TW.png",
  },
];
const Footer = memo(({ showLogo }: { showLogo: boolean }) => {
  const isMobile = useIsMobile();
  const { t } = useCustomTranslation();
  const changeLanguageHandle = useCallback(
    (item: ILanguageList) => {
      if (t("Language") !== item.Language) {
        changeLanguage(item.keyValue);
      }
      window.scrollTo(0, 0);
      storage.set("language", item.keyValue);
    },
    [t("Language")]
  );
  return (
    <HomeSection>
      <div className="footer_top">
        {showLogo ? (
          <div className="footer_item">
            <img
              src={preStaticUrl + "/img/zypher/logo.svg"}
              alt="zypher"
              className=" zypher_logo"
            />
          </div>
        ) : null}
        <div className="footer_item">
          <h4>{t("Language")}</h4>
          <ul className="language_item">
            {languageList.map((v) => (
              <li key={v.label} onClick={() => changeLanguageHandle(v)}>
                <p>{v.label}</p>
              </li>
            ))}
          </ul>
          <a href="/whitepaper" target="_blank" rel="noreferrer">
            <h4 className="whitepaper">White paper</h4>
          </a>
          <a href="/economics" target="_blank" rel="noreferrer">
            <h4>Econ paper</h4>
          </a>
          <a
            href="https://wiki.zypher.network/"
            target="_blank"
            rel="noreferrer"
          >
            <h4>Wiki</h4>
          </a>
        </div>
        <div className="footer_item">
          <h4>{t("Product")}</h4>
          <ul className="product_item">
            {SolutionsList.filter((v) => v.link).map((v) => (
              <li key={v.title}>
                <a href={v.link} target="_blank" rel="noreferrer">
                  {t(v.title)}
                </a>
              </li>
            ))}
            <li>
              <a
                className="product_item_white"
                href="https://drive.google.com/drive/folders/1hEpEikETzoxAMEFwZk7S5nksO1bdsrxh"
                target="_blank"
                rel="noreferrer"
              >
                <h4>Brand Kit</h4>
              </a>
            </li>
          </ul>
        </div>
        <div className="footer_item">
          <h4>{t("Social")}</h4>
          <CommunityLink className="footer_community" />
        </div>
      </div>
      <div className="footer_bottom">
        {isMobile ? (
          <>
            <p>Zypher Network 2025</p>
            <p>|</p>
            <p>{t("Privacy Policy")}</p>
            <p>|</p>
            <p>{t("Terms of Service")}</p>
          </>
        ) : (
          <>
            <p>Copyright © 2025 Zypher Network</p>
            <p>{t("Terms of Service")}</p>
            <p>{t("Privacy Policy")}</p>
          </>
        )}
      </div>
    </HomeSection>
  );
}, isEqual);
export default Footer;
