import { isEqual } from "lodash";
import { memo } from "react";
import HomeSection from "../widget/HomeSection/HomeSection";
import Title from "../widget/Title/Title";
import { preStaticUrl } from "../../../../constants/env_config";
const _preStaticUrl = preStaticUrl + "/img/zypher/resources/";
import "./Resources.scss";
import SvgComponent from "../../../../components/SvgComponent/SvgComponent";
import Button from "../../../../components/Button/Button";
import { useCustomTranslation } from "../../../../hooks/useCustomTranslation";
const Resources = memo(() => {
  const { t } = useCustomTranslation();
  return (
    <HomeSection id="build" classNames="resources">
      <Title
        title={t("Developer Resources")}
        subTitles={[
          "Our zk coprocessing solutions provide crypto-native",
          "applications with autonomous, composability, programmability, scalability and secret primitives.",
          // t("Our zk Game Engines provide crypto-native"),
          // t(
          //   "games with required composability, programmability, scalability and secret primitives."
          // ),
        ]}
      />
      <div className="resource_banner">
        <div className="resource_banner_inner">
          <div className="resource_bg1" />
          <div className="resource_banner_fl">
            <div className="resource_banner_seacher">
              <img src={preStaticUrl + "/img/icon/search.svg"} alt="search" />
              <p>{t("Search your zk solutions")}</p>
            </div>
            <div className="resource_banner_card">
              <p className="resource_banner_title">{t("Modules")}</p>
              <ul>
                {[
                  {
                    imgPath: _preStaticUrl + "celestia.png",
                    title: "Celestia",
                    content:
                      "Celestia is a modular data availability network that securely scales with the...",
                  },
                  {
                    imgPath: _preStaticUrl + "opbnb.png",
                    title: "opBNB",
                    content:
                      "An Optimized Layer-2 Solution That Delivers Lower Fees And Higher Throughput To...",
                  },
                  {
                    imgPath: _preStaticUrl + "linea.png",
                    title: "Linea",
                    content:
                      "A developer-ready zkEVM rollup for scaling Ethereum dapps",
                  },
                ].map((v) => (
                  <li key={v.title}>
                    <img src={v.imgPath} alt={v.title} />
                    <div>
                      <p className="resource_banner_tit">{v.title}</p>
                      <p className="resource_banner_content">{t(v.content)}</p>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="resource_banner_bottom">
                <p>{t("4 modules installed")}</p>
                <img
                  src={preStaticUrl + "/img/zypher/resources/zypher.png"}
                  alt="zypher"
                />
              </div>
            </div>
          </div>
          <div className="resource_banner_fr">
            <img
              src={_preStaticUrl + "sovereign_icon.svg"}
              alt="Sovereign Rollup"
              className="resource_banner_fr_img"
            />
            <p className="resource_banner_fr_title">Sovereign Rollup</p>
            <p className="resource_banner_fr_content">
              {t(
                "Provides the highest availability in the distributed system, and the entire system can be quickly upgraded and automatically deployed."
              )}
            </p>
          </div>
        </div>
        <div className="resource_banner_bg" />
      </div>
      <div className="resources_nav">
        <ul className="resources_nav_ul">
          {[
            {
              imgPath: "resources_nav1.png",
              title: "On-chain Contracts",
              content:
                "Provides rich precompiled contracts interfaces and Solidity/WASM/Move contracts for zk verifiers and other tools.",
              children: (
                <ul className="resources_nav1_list">
                  {[
                    "resources_nav1_01.svg",
                    "resources_nav1_02.svg",
                    "resources_nav1_03.svg",
                    "resources_nav1_04.svg",
                  ].map((v) => (
                    <li key={v}>
                      <img src={_preStaticUrl + v} />
                    </li>
                  ))}
                </ul>
              ),
            },
            {
              imgPath: "resources_nav2.png",
              title: "zk Toolchain",
              content:
                "A ZK engine library that developers can use to write zk circuits and plug-n-play zk functions, also support zk DSL / zk VM.",
              children: (
                <img
                  src={_preStaticUrl + "resources_nav2_01.png"}
                  className="img_nav_big"
                />
              ),
            },
          ].map((v) => (
            <NavItem item={v} key={v.title} />
          ))}
        </ul>
        <ul className="resources_nav_ul">
          {[
            {
              imgPath: "resources_nav3.png",
              title: "Server Sharding",
              content:
                "Distribute the game's world map into different nodes to improve carrying capacity of a single node.",
            },
            {
              imgPath: "resources_nav4.png",
              title: "Customized Network",
              content:
                "Games have high demands on the network. We have optimized the underlying p2p network layer so that it can support game scenarios.",
              children: (
                <img
                  src={_preStaticUrl + "resources_nav4_02.png"}
                  className="img_nav_big"
                />
              ),
            },
          ].map((v) => (
            <NavItem item={v} key={v.title} />
          ))}
        </ul>
        <ul className="resources_nav_ul">
          {[
            {
              imgPath: "resources_nav5.png",
              title: "Data Compatibility",
              content:
                "Built-in relational database and cache database to speed up the processing of game data.",
              children: (
                <img
                  src={_preStaticUrl + "resources_nav5_01.png"}
                  className="img_nav_big"
                />
              ),
            },
          ].map((v) => (
            <NavItem item={v} key={v.title} />
          ))}
          <li className="resources_nav_dev">
            <div className="resources_nav_dev_inner">
              <h4>{t("Developer Hub")}</h4>
              <p>{t("Build the next-gen decentralized game now.")}</p>
              <Button
                className="resources_nav_btn"
                classNameInner="resources_nav_btn_inner"
                label="Docs"
                onClick={() => {
                  window.open("https://docs.zypher.network", "_blank");
                }}
                right={
                  <SvgComponent
                    src={preStaticUrl + "/img/icon/arrow-right.svg"}
                    className={"resources_nav_btn_arrow"}
                  />
                }
              />
            </div>
          </li>
        </ul>
      </div>
    </HomeSection>
  );
}, isEqual);
type INavItem = {
  imgPath: string;
  title: string;
  content: string;
  children?: JSX.Element;
};
type INavItemProps = {
  item: INavItem;
};
const NavItem = memo(({ item }: INavItemProps) => {
  const { t } = useCustomTranslation();
  return (
    <li className="resources_nav_li">
      <NavImg src={item.imgPath} alt={item.title} />
      <NavTitle label={t(item.title)} />
      <NavContent label={t(item.content)} />
      {item?.children ?? null}
    </li>
  );
}, isEqual);
const NavImg = memo(({ src, alt }: { src: string; alt: string }) => {
  return (
    <img className="resource_nav_img" src={_preStaticUrl + src} alt={alt} />
  );
}, isEqual);
const NavTitle = memo(({ label }: { label: string }) => {
  return <p className="resource_nav_title">{label}</p>;
}, isEqual);
const NavContent = memo(({ label }: { label: string }) => {
  return <p className="resource_nav_content">{label}</p>;
}, isEqual);
export default Resources;
